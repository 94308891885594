
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import about_video_img from '../../assets/images/img1.jpg'
import history_img from '../../assets/images/img2.jpg'
import ModalVideo from 'react-modal-video'
 
export default class About1 extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal2 = this.openModal2.bind(this)
    }

    openModal2() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="about-st1-grid">
                        <div className="about-left">
                            <h1 className="about-left-title">
                                Approche du  <strong>FBR</strong>
                            </h1>
                            <p className="about-left-desc">
                                L'approche du Financement Basé sur les Résultats est une approche qui comprend une gamme plus ou moins complète des incitations pour le côté de l’offre et de la demande de soins. Il est matérialisé par un contrat de performance qui stipule que des ressources financières seront reversées aux prestataires en fonction de la quantité des prestations fournies sur la base d’indicateurs et ou de services définis préalablement et du respect des normes et standards de qualité des services et des soins.                            </p>
                            <Link to="/blog-fbr" className="theme-button">
                                En savoir plus <FiChevronRight className="icon" />
                            </Link>
                        </div>
                        <div className="about-right">
                            <img className="about-right-img" src={about_video_img} alt="About Video" />
                            {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='t3tsMEhlvwM' onClose={() => this.setState({ isOpen: false })} />
                            <div className="video-button-box">
                                <Link to="/#" className="video-button" onClick={this.openModal2}>
                                    <span className="icon"><FiPlay /></span>
                                    Watch Video
                                </Link>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img" src={history_img} alt="History" />
                            </div>
                            <div className="history-details-box">
                                <h1 className="history-details-title">
                                    More Than <strong>300 Projects </strong>
                                    Were Completed.
                                </h1>
                                <p className="history-details-desc">
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,
                                </p>
                                <Link to="/#" className="theme-button">
                                    Get Started <FiChevronRight className="icon" />
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        )
    }
}
