import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import BlogDetailsContent from '../components/blog/BlogDetailsContent'
import Footer from '../components/other/Footer'

export default class BlogDetailsPage extends Component {

    state = {
        blog: null
    }

    componentDidMount() {
        const { blog } = this.props.location.state;
        this.setState({
            blog
        });
    }

    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="News" />
                <BlogDetailsContent blog={this.state.blog}/>
                <Footer />
            </>
        )
    }
}
