
import React, { Component } from 'react'
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import About1 from '../components/about/About1'
import Divider from '../components/other/Divider'
import About from '../components/about/About'
import CounterUp from '../components/other/CounterUp'
import Slider from '../components/sliders/slider'
import SimpleTab from '../components/tabs/SimpleTab'
import Cta from '../components/other/Cta'
import Testimonial from '../components/team/Testimonial';
import Team from '../components/team/Team';
import ChartArea from '../components/other/ChartArea';
import Clients from '../components/other/Clients';
import Pricing from '../components/other/Pricing';
import CtaForm from '../components/other/CtaForm';
import Blog from '../components/blog/Blog';
import { Link } from 'react-router-dom'
import { FiChevronRight } from "react-icons/fi";
import Footer from '../components/other/Footer';

export default class Home extends Component {

    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Slider />
                <div className="h-service">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                <SectionsTitle title="Les objectifs spécifiques du FBR" />
                            </div>
                            {/* <div className="col-4">
                                <div className="service-button text-right">
                                    <Link to="/" className="theme-button">View All Services <FiChevronRight className="icon" /></Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <Service />
                </div>
                <Divider />
                <About1 />
                {/* <Divider /> */}
                {/* <About /> */}
                {/* <SimpleTab /> */}
                <CounterUp />
                {/* <section className="testimonial-wrapper ">
                    <div className="text-center">
                        <SectionsTitle title="Say About Us." subtitle="See What Our Customers" />
                    </div>
                    <Testimonial />
                </section> */}
                {/* <Cta />
                <Team />
                <Divider />
                <ChartArea /> */}
                {/* <Clients />
                <Pricing /> */}
                {/* <CtaForm /> */}
                {/* <Divider /> */}
                <div className="h-blog-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                <SectionsTitle title="Recents News" subtitle="Dernières informations" />
                            </div>
                            <div className="col-4 text-right">
                                <Link to="/blog-grid" className="theme-button">Voir plus <FiChevronRight className="icon" /></Link>
                            </div>
                        </div>
                    </div>
                    <Blog /> 
                </div>

                <Footer />
            </>
        )
    }
}

