import React, { Component } from 'react'
import singleimg from '../../assets/images/img16.jpg'
import img from '../../assets/images/img4.jpg'
import avata1 from '../../assets/images/testi-img1.jpg'
import { Link } from 'react-router-dom'
import { FiMessageCircle, FiCheckCircle, FiChevronsLeft, FiChevronsRight, FiThumbsUp, FiMeh, FiChevronRight } from 'react-icons/fi'
import { FaFacebookF, FaTwitter, FaInstagram, FaGooglePlusG, FaReply, FaQuoteLeft } from 'react-icons/fa'
import BlogSidebar from './BlogSidebar'
import Appservice from '../../service/FbrService';

export default class BlogDetailsContent extends Component {
    constructor() {
        super();
        this.state = {
            titreArticle: '',
            couvArticle: 'nocouv.png',
            dateArticle: '',
            bodyArticle: '',
        }
        this.FbrService = new Appservice();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.blog != this.props.blog) {
            if (this.props != undefined) {
                let val = this.props.blog;
                this.setState({
                    titreArticle: val.titreArticle,
                    dateArticle: val.dateArticle,
                    couvArticle: val.couvArticle,
                    bodyArticle: val.bodyArticle
                });
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="blog-details-wrapper">
                <div className="container">
                    <div className="blog-details-grid d-grid">
                        <div className="blog-details-left">
                            <div className="blog-details-img-box">
                                <img src={this.FbrService.download_file(this.state.couvArticle, 'photos')}/>
                            </div>
                            <div className="blog-details-contents">
                                <span className="blog-details-meta d-flex">{this.state.dateArticle} </span>
                                <h2>{this.state.titreArticle}</h2>
                                <p >
                                    <div dangerouslySetInnerHTML={{ __html: this.state.bodyArticle}} />
                                </p>
                    
                            </div>
                        </div>
                        <div className="blog-details-right">
                            <BlogSidebar />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
