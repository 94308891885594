import React, { Component } from 'react'
import about_video_img from '../../assets/images/img1.jpg'
import singleimg from '../../assets/images/img16.jpg'
import img from '../../assets/images/img4.jpg'
import avata1 from '../../assets/images/testi-img1.jpg'
import { Link } from 'react-router-dom'
import { FiMessageCircle, FiCheckCircle,FiDownload, FiChevronsLeft, FiChevronsRight, FiThumbsUp, FiMeh, FiChevronRight } from 'react-icons/fi'
import { FaFacebookF, FaTwitter, FaInstagram, FaGooglePlusG, FaReply, FaQuoteLeft } from 'react-icons/fa'
import BlogSidebar from './BlogSidebar'
import Appservice from '../../service/FbrService';

export default class FBRBlogDetailsContent extends Component {
    FbrService = new Appservice();
    states = {
        lists: [
            {
                list: "Augmenter l’utilisation des services de santé par la population en général et par les groupes vulnérables en particulier;"
            },
            {
                list: "Améliorer la qualité des soins et services de santé;"
            },
            {
                list: "Accroître l’efficience du système de santé;"
            },
            {
                list: "Andomised words which don't look"
            },
            {
                list: "Assurer l’équité dans l’accès aux soins de santé;"
            },
            {
                list: "Renforcer la motivation du personnel;"
            },
            {
                list: "Renforcer le système d’information sanitaire;"
            },
            {
                list: "Consolider le partenariat public – privé;"
            },
            {
                list: "Renforcer la gouvernance du système de santé à tous les niveaux."
            }
        ]
    }
    download_file(){
        this.FbrService.download_fichier_joint('Manuel_FBR.pdf', 'docs/')
    }
    componentDidMount() {
        //alert(JSON.stringify(this.props))
        //alert(window.location.pathname)
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section className="blog-details-wrapper">
                <div className="container">
                    <div className="blog-details-grid d-grid">
                        <div className="blog-details-left">
                            
                            <div className="blog-details-img-box">
                                <img src={about_video_img}/>
                            </div> 
                            <div className="blog-details-contents">
                                <span className="blog-details-meta d-flex">10 Avril 2021 </span>

                                <h2>1. Introduction</h2>

                                <p>
                                    Le Ministère de la Santé de la République de Guinée, suivant la mise en oeuvre des reformes (reforme 1: la réforme de Couverture Sanitaire Universelle (CSU) avec une réforme de financement de la santé) et des orientations (Orientation stratégique 3: Renforcement du système national de santé) de la Politique nationale de santé 2014, a opté pour la mise en oeuvre de la stratégie du Financement Basé sur les Résultats (FBR), une des actions prioritaires de la sous orientation stratégique 3.2: Financement adéquat pour un accès universel aux soins de santé, de la stratégie d’intervention: Renforcement des capacités de gestion financière et de la CSU, du plan national de développement sanitaire (PNDS) 2015-2024. Cette démarche vise l’amélioration de la performance du système national de santé (objectif stratégique 3 du PNDS). C’est pourquoi, en 2016, le Ministère de la santé à travers le bureau de stratégie et de développement (BSD), avec le soutien de ses partenaires techniques et financiers (Fonds Ebola de la coopération des Pays Bas, Consultant KIT, Banque Mondiale, GIZ IST et ONG Health Focus) a lancé le processus de l’implémentation du projet Pilote FBR et sa mise en place effective dans le district sanitaire de Mamou de 2016-2018.
                                    Suite aux résultats concluants de cette expérience, le Ministère de la Santé entend étendre progressivement la stratégie du FBR aux autres préfectures et ce, jusqu’à une couverture nationale. Pour cela, en plus de compter sur des ressources internes, le Ministère de la Santé compte sur l’appui de ses partenaires technique et financiers (PTF). Déjà, plusieurs PTF ont manifesté leur intérêt à accompagner le Ministère dans l’atteinte de cet objectif.
                                </p>

                                <h2>2. Objectif général du financement basé sur les résultats</h2>

                                <blockquote>
                                    <p>Contribuer à améliorer la performance du système de santé.</p>
                                    <span className="quote-icon">
                                        <FaQuoteLeft />
                                    </span>
                                </blockquote>

                                <h2>3. Objectifs spécifiques du financement basé sur les résultats</h2>
                                <div className="blog-cnt-mdl-right">
                                    <ul className="lists">
                                        {this.states.lists.map((item, index) => {
                                            return <li key={index}><FiCheckCircle style={{color: 'green'}} className="icon" /> {item.list}</li>
                                        })}
                                    </ul>
                                </div>
                                <br/>

                                <Link onClick={() => this.download_file()}  className="theme-button">Télécharger le manuel <FiDownload className="icon" /></Link>

                            </div>
                        </div>
                        <div className="blog-details-right">
                            <BlogSidebar />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
