import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiShoppingCart, FiSearch, FiChevronRight } from "react-icons/fi";
import logo from "../../assets/images/logo.png";
import HeaderBar from "../common/HeaderBar";
import { ProductConsumer } from '../../productcontext'
import $ from 'jquery'
import MenuCart from "./menucart/MenuCart";


export default function Navbar() {
    const [searchOpen, setsearchOpen] = useState(false)
    const [navOpen, setnavOpen] = useState(false)

    /*====  side-widget-menu  =====*/
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item.active', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass('active');
    })

    $(window).on('scroll', function () {

        // Header Menu Fixed
        if ($(window).scrollTop() > 30) {
            $('body').addClass('header-menu-fixed');
            $('.header-menu-wrapper').addClass('d-none');
            $('.scroll-d-block').addClass('d-block').removeClass('d-none');
        } else {
            $('body').removeClass('header-menu-fixed');
            $('.header-menu-wrapper').removeClass('d-none');
            $('.scroll-d-block').removeClass('d-block').addClass('d-none');
        }
    });

    return (
        <>
            <HeaderBar />

            <div className="header-menu-wrapper">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to="/">Accueil</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog-fbr">Présentation</Link>
                                    </li>
                                    <li>
                                        <Link to="/data-exploration">Exploration</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => {
                                            window.location.href = 'https://www.fbrguinee.org/portail/';
                                        }} >Connexion</Link>
                                    </li>
                                </ul>
                            </nav>

                            <div className="header-menu-right-flex">

                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div>
                                <div className="side-menu-open" onClick={() => setnavOpen(!navOpen)}>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="header-menu-wrapper d-none scroll-d-block">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <a href="/">
                                <img src={logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        <a href="/">
                                            Accueil
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/blog-fbr">Présentation</a>
                                    </li>
                                    <li>
                                        <a href="/data-exploration">Exploration</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact</a>
                                    </li>
                                    <li>
                                        <a href="https://www.fbrguinee.org/portail/">Connexion</a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">

                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div>
                                <div className="side-menu-open" onClick={() => setnavOpen(!navOpen)}>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setnavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li className="sidenav__item">
                            <Link to="/">Accueil</Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/blog-fbr">Présentation</Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/data-exploration">Exploration</Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/contact">contact</Link>
                        </li>
                        <li className="sidenav__item sidenav__item2 text-center">
                            <Link onClick={(e) => {
                                window.location.href = 'https://www.fbrguinee.org/portail/';
                            }}>Connexion</Link>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    );
}



