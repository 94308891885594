import React, { Component } from 'react'
import { AiOutlineCoffee, AiOutlineReconciliation, AiOutlineCheckCircle, AiFillDollarCircle, AiFillHome, AiTwotoneHome } from 'react-icons/ai'
import { FaHandshake } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import SectionsTitle from '../common/SectionsTitle'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import { Chart } from 'primereact/chart';
import 'primeflex/primeflex.css';
import FbrService from '../../service/FbrService';

export default class DashboardUp extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_data_score: [],
            items: [],
        }
        this.FbrService = new FbrService();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.FbrService.get_dashboard().then(data => {
            if (data !== undefined && data !== null) {
                this.setState({
                    liste_data: data.liste_facture_pref,
                    liste_data_score: data.liste_st_score
                });
                let items = [];
                items.push(
                    {
                        icon: <AiOutlineReconciliation className="icon" />,
                        number: <CountUp start={0}
                            end={data.nb}
                            duration={8}
                            separator=" "
                            decimal=","
                            prefix=" "
                            suffix=" "
                        />,
                        numsuffix: '',
                        title: 'Nombre de rapportage'
                    }
                )
                items.push(
                    {
                        icon: <AiOutlineCheckCircle className="icon" />,
                        number: <CountUp start={0}
                            end={data.taux_rp}
                            duration={8}
                            separator=" "
                            decimal=","
                            prefix=" "
                            suffix=" %"
                        />,
                        numsuffix: '',
                        title: 'Taux de rapportage'
                    }
                )
                items.push(
                    {
                        icon: <AiFillDollarCircle className="icon" />,
                        number: <CountUp start={1000}
                            end={data.mt/1000000}
                            duration={8}
                            separator=" "
                            decimal=","
                            prefix=" "
                            suffix=" M"
                        />,
                        numsuffix: '',
                        title: 'Montant total'
                    }
                )
                items.push(
                    {
                        icon: <AiOutlineCoffee className="icon" />,
                        number: <CountUp start={0}
                            end={data.nb_reco}
                            duration={8}
                            separator=" "
                            decimal=","
                            prefix=" "
                            suffix=" "
                        />,
                        numsuffix: '+',
                        title: 'Recommandations'
                    }
                )
                this.setState({items: items});
            }
        });

    }

    render() {
        const barchartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        min: 0,
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }]
            }
        };
        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_data != null && this.state.liste_data != undefined) {
            liste_label = this.state.liste_data.map(x => {
                return x.prefectureSite
            });
            liste_ds_stat = this.state.liste_data.map(x => {
                return x.mttObj
            });
        }
        const factureChart = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Facture',
                    backgroundColor: '#66BB6A',
                    data: liste_ds_stat
                }
            ]
        };

        let liste_label_score = null;
        let liste_ds_stat_score = null;

        if (this.state.liste_data_score != null && this.state.liste_data_score != undefined) {
            liste_label_score = this.state.liste_data_score.map(x => {
                return x.lib
            });
            liste_ds_stat_score = this.state.liste_data_score.map(x => {
                return x.dval
            });
        }
        const scoreChart = {
            labels: liste_label_score,
            datasets: [
                {
                    label: 'Scores',
                    backgroundColor: '#7dbdf1',
                    data: liste_ds_stat_score,
                    fill: true,
                    borderColor: '#42A5F5'
                }
            ]
        };


        return (
            <>
                {this.state.items.length > 0 ?
                    <section className="counterDash-wrapper text-center">
                        <div className="container">
                            <div className="counterDash-grid-wrap">
                                {this.state.items.map((item, index) => {
                                    return <div className="counterDash-items" key={index}>
                                        <div className="counterDash-iconbox">
                                            {item.icon}
                                        </div>
                                        <h1 className="counterDash-number">{item.number}{item.numsuffix}</h1>
                                        <p className="counterDash-title">{item.title}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    </section>
                    : null

                }

                <div style={{ padding: 30 }}>
                    <h2 style={{ color: '#999' }}>Statistiques des factures par préfecture</h2>
                    <hr />
                    <Chart type="bar" data={factureChart} options={barchartOptions} height="100w" />

                    <h2 style={{ color: '#999' }}>Statistiques des score des qualité</h2>
                    <hr />
                    <Chart type="line" data={scoreChart} options={barchartOptions} height="100w" />


                </div>

            </>
        )
    }
}
