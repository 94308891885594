import React, { Component } from 'react'
import { FiPhone, FiMapPin, FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { FaEnvelope, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'
import flat1 from '../../assets/images/flag.jpg'
import flat2 from '../../assets/images/flag2.jpg'
import flat3 from '../../assets/images/flag3.jpg'

export default class HeaderBar extends Component {
    render() {
        return (
            <div className="header-bar-wrapper">
                <div className="container">
                    <div className="header-bar-grid">
                        <div className="header-bar-left">
                            <ul className="d-flex">
                                <li className="d-flex"><FiPhone className="icon" /> <a href="tel:+224628550758">+224 628 550 758</a></li>
                                <li className="d-flex"><FaEnvelope className="icon" /> <a href="mailto:info@fbrguinee.org">info@fbrguinee.org</a></li>
                                {/* <li className="d-flex"><FiMapPin className="icon" /> 1st Avenue, Boston</li> */}
                            </ul>
                        </div>
                        <div className="header-bar-right text-right">
                            <div className="header-bar-right-flex d-flex">
                                <ul className="header-bar-social d-flex">
                                    <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                    <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                    <li><a href="https://youtube.com"><FaYoutube /></a></li>
                                </ul>
                                {/* <div className="header-bar-cta-btn">
                                    <a href="/" className="theme-button">Connexion <FiChevronRight className="icon" /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
