import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import { FiChevronRight } from 'react-icons/fi'

export default class owlcarousel extends Component {
    sliders = {
        items: [ 
            {
                title: 'Financement Basé sur les Résultats',
                description: "Une approche de financement du système de santé,axée sur les résultats, qui vise l’amélioration quantitative et qualitative de l’offre de soins à travers une approche contractuelle.",
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-1',
                col: '8'
            },
            {
                title: 'FBR dans le ditrict de Mamou',
                description: 'Lancement officiel des activités du FBR dans le ditrict de Mamou avec Enabel',
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-10',
                col: '12'
            },
            {
                title: 'Dr Daman KEITA',
                description: "Coordonateur de la Cellule Technique Nationale (CTN-FBR)",
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-7',
                col: '12'
            },
            {
                title: 'Atélier de formation à Kindia',
                description: "Visite du DRS de Kindia à l'atélier de formation sur le portail FBR du 12/07  au 16/07 2021",
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-8',
                col: '8'
            },
            {
                title: 'Atélier de formation à Kindia',
                description: "Atélier de formation des acteurs des régions de Kankan et de Kindia sur le portail FBR du 12/07  au 16/07 2021",
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-9',
                col: '8'
            },
            {
                title: 'Concertation avec les partenaires',
                description: 'Compte rendu de la reunion de concertation entre la CTN-FBR et les partenaires techniques et financiers',
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-3',
                col: '8'
            },
            {
                title: 'Santé pour tous',
                description: "Augmenter l’utilisation des services de santé par la population en général et par les groupes vulnérables en particulier",
                button1: 'En savoir plus',
                button2: '',
                bg: 'bg-2',
                col: '8'
            }
        ]
    }
    render() {
        return (
            <section className="hero-slider-wrapper">
                <OwlCarousel className="owl-theme" loop={true} autoplay={true} margin={10} dots={false} nav items={1} >
                    {this.sliders.items.map((item, index) => {
                        return <div key={index} className={'hero-slider-item ' + item.bg}>
                            <div className="container">
                                <div className="row">
                                    <div className={'col-' + item.col}>
                                        <div className="hero-slider-content">
                                            <h1>{item.title}</h1>
                                            <p>{item.description}</p>
                                            <div className="hero-slider-btns">
                                                <Link to="/blog-fbr" className="theme-button">{item.button1} <FiChevronRight className="icon" /></Link>
                                                <Link to="/blog-fbr" className="theme-button">{item.button2} <FiChevronRight className="icon" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </OwlCarousel>
            </section >
        )
    }
}
