import Numeral from 'numeral';
export default class FbrService {
    constructor() {
        this.state = {
           // wsurl: 'http://localhost:7777',
            wsurl: 'https://www.fbrguinee.org/ws_fbr',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
    }

    wsurl() {
        return this.state.wsurl;
    }

    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    get_userconnected() {
        return this.state.userconnected;
    }


    download_fichier_joint(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_fichier_joint?namefile=' + namefile + '&destination=' + destination + '&iduser=1001';
        window.location = lien;
    }

    download_file(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile + '&destination=' + destination + '&iduser=1001';
        return lien
    }

    get_dashboard() {
        var lien = this.wsurl() + '/etats/get_dashboard_public';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_article(spd1, spd2) {
        var lien = this.wsurl() + '/article/get_liste_article?pd1=' + spd1 + '&pd2=' + spd2;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

   


}