import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Appservice from '../../../service/FbrService';

export default function BlogWidgetFeatured({ blog }) {
    const { title, authorurl, date, author, images,dateArticle, titreArticle,couvArticle } = blog;
    const FbrService = new Appservice();
    let fic = couvArticle;
    if (fic === undefined || fic === null || fic === '') {
        fic = 'nocouv.png';
    } 
    return (
        <>
            <li className="d-flex">
                <Link  to={{
                    pathname: '/blog-details',
                    state: {blog: blog}
                }} onClick={(e) => { 
                    if(window.location.pathname!=='/blog-fbr'){
                        window.location.reload() 
                    }
                    }}>
                    <img src={FbrService.download_file(fic, 'photos')}   />
                </Link>
                <div className="body">
                    <Link  to={{
                    pathname: '/blog-details',
                    state: {blog: blog}
                }} onClick={(e) => { 
                    if(window.location.pathname!=='/blog-fbr'){
                        window.location.reload() 
                    }
                }}>
                        {titreArticle}
                    </Link>
                    <span className="date">
                        {dateArticle}
                    </span>
                </div>
            </li>
        </>
    )
}

 

BlogWidgetFeatured.propTypes = {
    blog: PropTypes.shape({
        titreArticle: PropTypes.string.isRequired,
    })
};
