import React, { Component } from 'react'
import { AiOutlineNotification, AiFillBuild, AiOutlineCheckCircle,AiOutlineHeatMap,AiFillHome ,AiTwotoneHome} from 'react-icons/ai'
import { FaHandshake } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import SectionsTitle from '../common/SectionsTitle'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';


export default class CounterUp extends Component {

    counterstates = {
        items: [
            {
                icon: <AiTwotoneHome className="icon" />,
                number: <CountUp start={0}
                    end={2}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '',
                title: 'Directions Régionales Sanitaires'
            },
            {
                icon: <AiTwotoneHome className="icon" />,
                number: <CountUp start={0}
                    end={4}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '',
                title: 'Districts Sanitaires'
            },
            {
                icon: <AiOutlineCheckCircle className="icon" />,
                number: <CountUp start={0}
                    end={36}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '+',
                title: 'Indicateurs quantité'
            },
            {
                icon: <FaHandshake className="icon" />,
                number: <CountUp start={0}
                    end={28}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '+',
                title: 'Indicateurs qualité'
            }
        ]
    }
    render() {
        return (
            <>
           
                <section className="counterup-wrapper text-center">
                    <div className="container">
                        <SectionsTitle title="Quelques chiffres" />
                        <div className="counterup-grid-wrap">

                            {this.counterstates.items.map((item, index) => {
                                return <div className="counterup-items" key={index}>
                                    <div className="counterup-iconbox">
                                        {item.icon}
                                    </div>
                                    <h1 className="counterup-number">{item.number}{item.numsuffix}</h1>
                                    <p className="counterup-title">{item.title}</p>
                                </div>
                            })}

                        </div>
                        
                    </div>
                </section>
            </>
        )
    }
}
