import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdMailOutline } from 'react-icons/md'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGooglePlusG } from 'react-icons/fa'
export default class CtaForm extends Component {
    render() {
        return (
            <section className="ctaform-wrapper">
                <div className="container">
                    <div className="ctaform-grid">
                        <div className="ctaform-left">
                            <SectionsTitle title="Notre contact" />
                            <div className="ctaform-contact-info-grid text-center">
                                <div className="item">
                                    <FiPhoneCall className="icon" />
                                    <h2 className="item-title">Téléphone</h2>
                                    <p>+224 628 550 758</p>
                                </div>
                                <div className="item">
                                    <MdMailOutline className="icon" />
                                    <h2 className="item-title">Notre Email</h2>
                                    <p>info@fbrguinee.org</p>
                                </div>
                            </div>
                            <SectionsTitle title="Suivez nous sur" />
                            <div className="sidebar-social-profile">
                                <ul className="contact-form-socials d-flex">
                                    <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                    <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                    <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                    <li><a href="https://linkedin.com"><FaLinkedinIn /></a></li>
                                    <li><a href="https://plus.google.com"><FaGooglePlusG /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="ctaform-right">
                            <form>
                                <div className="form-grid">
                                    <input type="text" name="name" placeholder="Nom" />
                                    <input type="EMAIL" name="email" placeholder="Email" />
                                </div>
                                <input type="text" name="text" placeholder="Objet" />
                                <textarea id="textarea" placeholder="Message" ></textarea>
                                <button type="button" value="Envoyer" className="theme-button">Envoyer</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
