import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/images/logo2.png'
import { FiPhone, FiChevronRight, FiHeart } from 'react-icons/fi'
import { FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import ScrollTopBtn from '../layouts/ScrollTopBtn'

export default class Footer extends Component {
    render() {
        return (
            <>
                <ScrollTopBtn />
                <footer style={{backgroundColor: '#1a1a1a',padding: 20}} >
                    <div className="copyright-wrap text-center">
                        <p style={{color: 'white'}}>&copy; Copyright 2021  <a style={{color: 'yellow'}} href="https://sante.gov.gn/">Ministère de la Santé</a>.</p>
                    </div>
                </footer>
            </>
        )
    }
}
