import React from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FiPlus, FiChevronRight } from 'react-icons/fi'
import { FaRegComment } from 'react-icons/fa'
import Appservice from '../../service/FbrService';

export default function BlogFeatures({ blog }) {
    const { title, button, authorurl, commentnum, dateArticle, titreArticle, couvArticle, images, description } = blog;
    const FbrService = new Appservice();
    let fic = couvArticle;
    if (fic === undefined || fic === null || fic === '') {
        fic = 'nocouv.png';
    }  
    return (
        <div className="blog">
            <div className="blog-img-box">
                <img src={FbrService.download_file(fic, 'photos')} alt="Blog" />
                <div className="blog-overlay">
                    <Link to={{
                    pathname: '/blog-details',
                    state: {blog: blog}
                }}><FiPlus className="icon" /></Link>
                </div>
            </div>
            <div className="blog-content-box">
                <span className="blog-meta">{dateArticle}</span>
                <Link to={{
                    pathname: '/blog-details',
                    state: {blog: blog}
                }}>
                    <h2 className="blog-title">
                        {titreArticle}
                    </h2>
                </Link>


            </div>
        </div>

    )
}

BlogFeatures.propTypes = {
    blog: PropTypes.shape({
        titreArticle: PropTypes.string.isRequired,
    })
};
