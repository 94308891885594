import React, { Component } from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import About from '../components/about/About';
import Service3clmns from '../components/services/Service3clmns';
import SimpleTab from '../components/tabs/SimpleTab';
import DashboardUp from '../components/other/DashboardUp';
import Team from '../components/team/Team';
import Clients from '../components/other/Clients';
import CtaForm from '../components/other/CtaForm';
import Footer from '../components/other/Footer';

export default class About_Page extends Component {
    render() {
        return (
            <main className="about-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Exploration" />
                <DashboardUp />
               
                <Footer />
            </main>
        )
    }
}
