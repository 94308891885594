import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGooglePlusG } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import ContactSidebar from './ContactSidebar'
import Maps from '../contact/Maps'
import SubscribeForm from '../other/SubscribeForm'
import CtaForm from '../../components/other/CtaForm';

export default class ContactDetails extends Component {
    render() {
        return (
            <section className="contact-form-wrapper">
                <div className="container">
                    <CtaForm />
                    

                    
                </div>
            </section>
        )
    }
}
